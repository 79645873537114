<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="ASSIGNMENT_TYPES"
      tooltip-title="ASSIGNMENT_TYPES"
      :display-breadcrumb="true"
    ></TitleWrapper>
    <template v-if="isLoading"><Loader class="mt-20 mb-40" :content="true" /></template>
    <div v-else-if="!isLoading && isEmpty(assignmentTypes)">
      <NoRecordFound />
    </div>
    <TableWrapper v-else>
      <THead>
        <TRHead>
          <TH v-for="(head, index) in tableHeaders" :key="index">
            {{ head }}
          </TH>
        </TRHead>
      </THead>
      <TBody>
        <TRBody v-for="(type, index) in assignmentTypes" :key="index">
          <TD>
            {{ type.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD>
            {{ type.description || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD>
            <TableAction
              :idx="index"
              :current-user="type"
              :action-list="actionList"
              @action="typeAction"
            />
          </TD>
        </TRBody>
      </TBody>
    </TableWrapper>
    <Pagination
      v-if="showPagination"
      v-show="!isLoading"
      :key="forceRender"
      :record-limit="GENERAL_CONSTANTS.RECORD_LIMIT"
      :max-range="paginationCounts(assignmentCounts, GENERAL_CONSTANTS.RECORD_LIMIT)"
      @filterRecord="filterRecords"
    />
    <AssignmentTypesModal
      v-if="showModal"
      :modal="showModal"
      :edit="currentSelectedType"
      @toggle="toggleAssignmentTypeModal"
    />
    <UIConfirmationModal
      v-if="confirmation"
      heading="DELETE_A_T"
      message="Are you sure you want to delete "
      button-text="Delete"
      :modal="confirmation"
      :name="currentSelectedType.title"
      remaining-message="assignment type ?"
      @cancel="toggleConfirmationModal"
      @confirm="deleteType"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue'

// Components
import NoRecordFound from '@src/components/BaseComponent/NoRecordFound.vue'
import TitleWrapper from '@components/TitleWrapper.vue'
import TableAction from '@components/TableAction.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import AssignmentTypesModal from '@src/router/views/assignments/modals/AddAndUpdateAssignmentTypeModal.vue'
import UIConfirmationModal from '@src/components/UiElements/UIConfirmationModal.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
// Own modules
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'
import {
  paginationCounts,
  paginationRangeHandler,
} from '@src/components/BaseComponent/pagination.util.js'
// Third Party
import isEmpty from 'lodash/isEmpty'

// Store
import { useStore } from 'vuex'
const store = useStore()

// States
const isLoading = ref(false)
const showPagination = ref(false)
const currentSelectedType = ref(null)
const assignmentTypes = ref([])
const assignmentCounts = ref(0)
const forceRender = ref(0)
const tableHeaders = ref(['Title', 'Description', 'Actions'])
const actionList = ref([{ name: 'Edit' }, { name: 'Delete' }])
const confirmation = ref(false)
// Computed
const currentClassScope = computed(() => store.state.layout.currentClassScope)
const showModal = computed(() => store.state.layout.showModal)

// watchers

watch(
  () => currentClassScope.value,
  () => {
    filterRecord()
  },
)

// Mounted Hook
onMounted(() => {
  setRightbarData()
  filterRecords()
})

// Methods

const filterRecords = (range) => {
  isLoading.value = true
  let paginationPayload = paginationRangeHandler(range)
  getAssignmentTypes(paginationPayload)
    .then((response) => {
      assignmentTypes.value = response?.data?.records
      assignmentCounts.value = response?.data?.meta?.total_records
      showPagination.value = assignmentCounts.value > GENERAL_CONSTANTS.RECORD_LIMIT
      setRightbarData(assignmentCounts.value)
    })
    .finally(() => {
      isLoading.value = false
    })
}

const typeAction = (action, currentType) => {
  currentSelectedType.value = currentType
  switch (action) {
    case 'Edit':
      toggleAssignmentTypeModal()
      break
    case 'Delete':
      toggleConfirmationModal()
      break
  }
}

const toggleAssignmentTypeModal = (payload) => {
  store.dispatch('layout/setShowModal', !showModal.value)
  if (!showModal.value) currentSelectedType.value = null
  if (payload) filterRecords()
  if (payload === 'fetch') forceRender.value++
}

const setRightbarData = (count = 0) => {
  const rightBarContent = {
    header: {
      title: 'RB_AT',
      buttons: [
        {
          title: 'RB_AAT',
          classes: ['primary-button-right'],
          action: { name: 'layout/setShowModal', payload: {} },
        },
      ],
    },
    stats: [
      {
        title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
        categories: [
          {
            text: 'TOTAL_NO_TYPES',
            value: count,
            tooltipDescription: 'Total types of assignment types for an institute',
          },
        ],
      },
    ],
  }
  store.dispatch('layout/setRightbarContent', rightBarContent)
}

const toggleConfirmationModal = () => {
  confirmation.value = !confirmation.value
  if (!confirmation.value) currentSelectedType.value = null
}

const deleteType = () => {
  let id = currentSelectedType.value.id
  deleteAssignmentType(id).then(() => {
    forceRender.value++
    store.commit('toast/NEW', { message: 'Deleted Successfully', type: 'success' })
    toggleConfirmationModal()
    filterRecords()
  })
}
// Store actions
const getAssignmentTypes = (payload) => store.dispatch('assignment/getAssignmentTypes', payload)
const deleteAssignmentType = (id) => store.dispatch('assignment/deleteAssignmentType', id)
</script>
