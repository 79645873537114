<template>
  <div>
    <ValidationObserver @submit="addAssignmentType">
      <UiModalContainer
        footer-classes="flex justify-end gap-3"
        :modal-show="modal"
        :modal-width="50"
        @handleClick="handleClick"
      >
        <template v-slot:header>
          <span>
            {{ modalHeading }}
          </span>
        </template>
        <div>
          <div class="py-6 px-6">
            <div class="flex flex-col">
              <InputBox
                v-model.trim="assignmentTypeData.title"
                type="text"
                name="TITLE"
                title="TITLE"
                label="Assignment Title"
                placeholder="TITLE"
                :class="$style.widthItems"
                class="flex-1 w-full"
                rules="required|alpha_numeric_spaces"
              />
              <InputBox
                v-model="assignmentTypeData.description"
                type="text"
                name="Description"
                title="Description"
                label="Description"
                placeholder="Description"
                :class="$style.widthItems"
                class="flex-1 w-full"
              />
            </div>
          </div>
        </div>
        <template v-slot:footer>
          <button
            class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button-outline"
            @click="emit('toggle')"
          >
            Cancel
          </button>
          <button
            class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button"
          >
            <span v-if="isLoading">
              <Loader />
            </span>
            <span v-else>
              {{ successButtonTitle }}
            </span>
          </button>
        </template>
      </UiModalContainer>
    </ValidationObserver>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'

// Components
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import InputBox from '@components/UiElements/UiInputBox.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { Form as ValidationObserver } from 'vee-validate'

// Store
import { useStore } from 'vuex'
const store = useStore()

// Props
const { edit } = defineProps({
  modal: {
    type: Boolean,
    default: false,
  },
  edit: {
    type: Object,
    default: () => {},
  },
})

// Emits
const emit = defineEmits(['toggle'])

// States
const assignmentTypeData = ref({
  title: '',
  description: '',
})
const isLoading = ref(false)

const modalHeading = computed(() => {
  return edit ? 'Edit Assignment Type' : 'Add Assignment Type'
})

const successButtonTitle = computed(() => {
  return edit ? 'Update' : 'Save'
})

// Mounted
onMounted(() => {
  if (edit) setEditCategoryData()
})

const addAssignmentType = () => {
  isLoading.value = true
  const data = { assignmentType: assignmentTypeData.value }
  if (edit) data.id = edit.id
  addAndEditAssignmentTypes(data)
    .then(() => {
      let message = `${assignmentTypeData.value.title} ${
        edit?.id ? 'updated' : 'added'
      } Successfully `
      store.commit('toast/NEW', { message: message, type: 'success' })
      emit('toggle', 'fetch')
    })
    .finally(() => {
      isLoading.value = false
    })
}

const handleClick = (eventName) => {
  switch (eventName) {
    case 'close':
      emit('toggle')
      break
  }
}

const setEditCategoryData = () => {
  assignmentTypeData.value.title = edit?.title || ''
  assignmentTypeData.value.description = edit?.description || ''
}

const addAndEditAssignmentTypes = (data) =>
  store.dispatch('assignment/addAndEditAssignmentTypes', data)
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>
